/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 20,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    React.createElement('svg', {
      className: classes.svg,
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 176.03 115.82"   ,}
    
      , React.createElement('rect', {
          x: "213.75",
          y: "374.46",
          width: "28.46",
          height: "83.98",
          rx: "11.19",
          transform: "translate(242.2 463.84) rotate(-180)"  ,
          fill: "#4eb5e7",}
        )
        , React.createElement('rect', {
          x: "287.53",
          y: "369.06",
          width: "28.46",
          height: "83.98",
          rx: "11.19",
          transform: "translate(389.78 453.03) rotate(180)"  ,
          fill: "#f7bc17",}
        )
        , React.createElement('rect', {
          x: "361.32",
          y: "374.46",
          width: "28.46",
          height: "83.98",
          rx: "11.19",
          transform: "translate(537.35 463.84) rotate(-180)"  ,
          fill: "#4eb5e7",}
        )
        , React.createElement('rect', {
          x: "325.7",
          y: "396.75",
          width: "28.46",
          height: "83.98",
          rx: "11.19",
          transform: "translate(466.12 508.43) rotate(-180)"  ,
          fill: "#ec2027",}
        )
        , React.createElement('rect', {
          x: "251.24",
          y: "400.9",
          width: "28.46",
          height: "83.98",
          rx: "11.19",
          transform: "translate(317.18 516.71) rotate(180)"  ,
          fill: "#32a850",}
        )
    )
  );
};

export default LogoIcon;
